import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountCircle, Add, ContentCopy } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Avatar,
} from "@mui/material";

import pages from "../../constants/pages";
import { useAlert, useAxios } from "../../hooks";
import { useEffect } from "react";

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const Card = styled(Paper)`
  flex-grow: 1;

  box-shadow: 0px 17px 80px rgba(0, 0, 0, 0.05),
    0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0359427),
    0px 3.79717px 17.869px rgba(0, 0, 0, 0.0298054),
    0px 2.12866px 10.0172px rgba(0, 0, 0, 0.025),
    0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0201946),
    0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0140573);
  border-radius: 10px;
`;

export default function List() {
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploadedID, setUploadedID] = useState([]);

  const axios = useAxios({ baseURL: "webCheckIn" });

  useEffect(() => {
    var number = localStorage.getItem("phone-id");
    axios({
      url: "/members?mobile_no=" + number,
    }).then((response) => {
      if (response.status) {
        setUploadedID(response.data);
      }
    });
  }, [axios]);

  function handleCopy(value) {
    dispatch(alert({ type: "success", message: "Copied" }));
    navigator.clipboard.writeText(value);
  }

  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={8}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            List of ID you had uploaded
          </Typography>
        </Grid>

        <Grid item xs={4} textAlign="end">
          <Tooltip title="Add ID">
            <IconButton
              onClick={() => navigate(pages.documentPage.route)}
              style={{ color: "white", background: "#1976d2" }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>

        {uploadedID.map((id, index) => (
          <Grid item xs={12} style={{ marginTop: "10px" }} key={index}>
            <Card
              style={{
                background:
                  "linear-gradient(rgb(25 118 210 / 45%) 7.29%,rgba(1, 87, 155, 0) 77.08%)",
              }}
            >
              <Grid
                container
                spacing={4}
                style={{
                  padding: "10px",
                }}
              >
                <Grid item xs={3}>
                  <Avatar
                    style={{
                      color: "#fff",
                      background: "rgb(25, 118, 210)",
                      height: "60px",
                      width: "60px",
                    }}
                    color="primary"
                  >
                    <AccountCircle style={{ fontSize: "45px" }} />{" "}
                  </Avatar>
                </Grid>

                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Name</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">{id.name}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1">Verified Document</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">{id.doc_type}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1">Digiyatra ID</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1">
                        {id.digiyatra_id.length > 10
                          ? id.digiyatra_id.substring(0, 16)
                          : id.digiyatra_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign="center">
                      <ContentCopy
                        fontSize="20px"
                        color="primary"
                        onClick={() => handleCopy(id.digiyatra_id)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
}
