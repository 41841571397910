import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button } from "@mui/material";
import { IoIosArrowDropright } from "react-icons/io";

import woman from "../../assets/woman.png";
import TextField from "../../components/TextField";

const useStyle = makeStyles(() => ({
  welcomeTypography: {
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "38px",
    color: "#FFFFFF",
  },

  welcomeDiv: {
    textAlign: "center",
    alignSelf: "center",
  },

  outerDivStyle: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundImage: `url(${woman})`,
    mixBlendMode: "normal",
  },

  innerDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background:
      "linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%)",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function FormDisplay({ formikProps }) {
  const classes = useStyle();

  return (
    <div className={classes.outerDivStyle}>
      <div className={classes.innerDivStyle}>
        <Grid container spacing={4} p="20px">
          <Grid item xs={12} className={classes.welcomeDiv}>
            <Typography variant="h3" className={classes.welcomeTypography}>
              WELCOME USER
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextField
              fullWidth
              label="PNR"
              name="pnr"
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextField
              label="Email/Last Name"
              name="userName"
              fullWidth
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              style={{ color: "#fff", padding: "10px", fontSize: "18px" }}
              className={classes.buttonStyle}
              fullWidth
              onClick={formikProps.handleSubmit}
              endIcon={<IoIosArrowDropright />}
            >
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
