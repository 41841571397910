import token from "./helper function/token";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const authToken = createAsyncThunk("logIn/token", token);

const initialState = {
  authToken: "",
  identityType: "",
  aadharNo: "",
  name: "",
  gender: "",
  dob: "",
  address: "",
  dlNo: "",
};

const identitySlice = createSlice({
  name: "userIdentityDetails",
  initialState,
  reducers: {
    setAadharData(_, { payload }) {
      return {
        identityType: payload.identityType,
        aadharNo: payload.aadharNo,
        name: payload.name,
        gender: payload.gender,
        dob: payload.dob,
        address: payload.address,
      };
    },
    setDLData(_, { payload }) {
      return {
        identityType: payload.identityType,
        dlNo: payload.dlNo,
        name: payload.name,
        dob: payload.dob,
        address: payload.address,
      };
    },
    setIdentity(_, { payload }) {
      return {
        identityType: payload,
      };
    },
  },
  extraReducers: {
    [authToken.fulfilled]: (_, { payload }) => {
      return payload;
    },
  },
});

export const { setAadharData, setIdentity, setDLData } = identitySlice.actions;
export default identitySlice.reducer;
