import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import FormDisplay from "./FormDisplay";
import { useAxios } from "../../hooks";

export default function DigiYatraID() {
  const [pnr, setPnrs] = useState([]);
  const axios = useAxios({ baseURL: "webCheckIn" });
  const [formData, setFormData] = useState({ pnr: "" });

  let number = localStorage.getItem("phone-id");

  useEffect(() => {
    axios({
      url: "/pnrs?mobile_no=" + number,
    }).then((response) => {
      if (response.status) {
        setPnrs(response.data);
      }
    });
  }, [number, axios]);

  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            DigiYatra ID Registration
          </Typography>
        </Grid>
      </Grid>
      <FormDisplay
        formData={formData}
        setFormData={setFormData}
        pnr={pnr}
        setPnrs={setPnrs}
      />
    </React.Fragment>
  );
}
