import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./signInObjects";
import { signInMember } from "../../redux/slices/userSlice";

export default function Form({ formData, setFormData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={async (formData, { setSubmitting }) => {
        await dispatch(
          signInMember({
            pnr: formData.pnr,
            userName: formData.userName,
          })
        );
        navigate(
          `/passenger-details?pnr=${formData.pnr}&userName=${formData.userName}`
        );
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay formikProps={formikProps} />
        </React.Fragment>
      )}
    </Formik>
  );
}
