import QrScan from "react-qr-reader";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { QrCodeScanner } from "@mui/icons-material";
import { Typography, Grid, MenuItem, IconButton } from "@mui/material";

import pages from "../../../constants/pages";
import TextField from "../../../components/PrimaryTextField";

export default function Form({ data, setData, formikProps, digiyatraDialog }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {!data.state ? (
        <Fragment>
          <Grid item xs={12}>
            <Typography>Passenger Details</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              formikProps={formikProps}
              name="name"
              label="Passenger Name"
              disabled={!digiyatraDialog.edit}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              formikProps={formikProps}
              name="age"
              label="Passenger Age"
              disabled={!digiyatraDialog.edit}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              formikProps={formikProps}
              name="gender"
              label="Passenger Gender"
              disabled={!digiyatraDialog.edit}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              formikProps={formikProps}
              name="digiyatra_id"
              label="Digiyatra ID"
              InputProps={{
                endAdornment: (
                  <IconButton color="primary" onClick={handleScanQr}>
                    <QrCodeScanner />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="button"
              color="primary"
              onClick={() => navigate(pages.documentPage.route)}
            >
              Create Digiyatra ID
            </Typography>
          </Grid>
        </Fragment>
      ) : (
        <Grid
          item
          xs={12}
          textAlign="center"
          style={{
            height: "320px",
            width: "320px",
          }}
        >
          <QrScan delay={300} onError={handleError} onScan={handleScan} />
        </Grid>
      )}
    </React.Fragment>
  );

  function handleScanQr() {
    setData({
      state: true,
      data: "",
    });
  }

  function handleScan(data) {
    if (data) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        digiyatra_id: data,
      }));
      setData((prevVal) => ({
        state: false,
        data: data,
      }));
    }
  }

  function handleError(err) {
    console.error(err);
  }
}
