import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";

import woman from "../../assets/woman.png";
import pages from "../../constants/pages";

const useStyle = makeStyles(() => ({
  welcomeTypography: {
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "38px",
    color: "#FFFFFF",
  },

  welcomeDiv: {
    textAlign: "center",
    alignSelf: "center",
  },

  outerDivStyle: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundImage: `url(${woman})`,
    mixBlendMode: "normal",
  },

  innerDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background:
      "linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%)",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function Home() {
  const classes = useStyle();
  const navigate = useNavigate();
  var registrationStatus = localStorage.getItem("registration-status");
  console.log(registrationStatus);
  function handleBoardingPass() {
    navigate(pages.boardingPassPage.route);
  }

  function handleRegistration() {
    navigate(pages.documentPage.route);
  }

  return (
    <div className={classes.outerDivStyle}>
      <div className={classes.innerDivStyle}>
        <Grid container spacing={4} p="20px">
          {registrationStatus === "true" && (
            <Grid item xs={12} textAlign="center">
              <Button
                style={{ color: "#fff", padding: "10px", fontSize: "18px" }}
                className={classes.buttonStyle}
                fullWidth
                onClick={handleBoardingPass}
                endIcon={<IoIosArrowDropright />}
              >
                Upload Boarding Pass
              </Button>
            </Grid>
          )}

          {registrationStatus === "false" && (
            <Grid item xs={12} textAlign="center">
              <Button
                style={{ color: "#fff", padding: "10px", fontSize: "18px" }}
                className={classes.buttonStyle}
                fullWidth
                onClick={handleRegistration}
                endIcon={<IoIosArrowDropright />}
              >
                Registration
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}
