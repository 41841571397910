export default function convertDate(dates) {
  var backendDate = dates.split("-");
  var sortedDate = backendDate[1] + "-" + backendDate[0] + "-" + backendDate[2];
  var timeStamp = new Date(sortedDate);

  if (!(timeStamp > 0)) {
    return null;
  }

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var days = ["Sun", "Mon", "Tues", "Wed", "Thru", "Fri", "Sat"];
  var year = timeStamp.getFullYear();
  var month = months[timeStamp.getMonth()];
  var day = days[timeStamp.getDay()];

  var date =
    timeStamp.getDate() < 10 ? "0" + timeStamp.getDate() : timeStamp.getDate();

  var time = day + ", " + date + " " + month + " " + year;

  return time;
}
