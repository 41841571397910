import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { IoIosAirplane } from "react-icons/io";
import { Grid, Typography } from "@mui/material";

import PassengerDetails from "./PassengerDetails";
import ButtonFooter from "./ButtonFooter";
import { upperCase } from "change-case-all";

const useStyle = makeStyles(() => ({
  desc: {
    fontFamily: "'Roboto',sans-serif",
    fontStyle: "normal",
    fontWeight: "500!important",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#FFFFFF",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "26px",
    color: "#fff",
    fontFamily: "'Roboto',sans-serif",
    lineHeight: "30px",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function FlightDetail() {
  const classes = useStyle();

  const passengerDetails = useSelector((state) => state.passengerDetails);

  return (
    <React.Fragment>
      <Grid style={{ height: "200px" }} container spacing={1} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            Web Check - In
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "30px" }} />

        <Grid item xs={3} textAlign="center" alignSelf={"center"}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "22px",
              color: "#fff",
              lineHeight: "26px",
            }}
          >
            {passengerDetails.sourceCode}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center" alignSelf={"center"}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#fff",
              lineHeight: "16px",
            }}
          >
            {passengerDetails.totalHours &&
              upperCase(passengerDetails.totalHours)}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign={"center"} alignSelf={"center"}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "22px",
              color: "#fff",
              lineHeight: "26px",
            }}
          >
            {passengerDetails.destinationCode}
          </Typography>
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={3} textAlign={"center"}>
          <Typography
            style={{
              fontSize: "16px",
              color: "#fff",
              lineHeight: "19px",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.source}
          </Typography>
        </Grid>

        <Grid item xs={6} alignSelf={"center"}>
          <Grid container spacing={1}>
            <Grid item xs textAlign="end" style={{ color: "#fff" }}>
              <Typography style={{ fontSize: "13px" }}>
                •••••••••••••••••
              </Typography>
            </Grid>

            <Grid item textAlign="center" alignSelf={"center"}>
              <IoIosAirplane style={{ color: "#fff", fontSize: "18px" }} />
            </Grid>

            <Grid item xs textAlign="start" style={{ color: "#fff" }}>
              <Typography style={{ fontSize: "13px" }}>
                •••••••••••••••••
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} textAlign={"center"}>
          <Typography
            style={{
              fontSize: "16px",
              color: "#fff",
              lineHeight: "19px",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.destination}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "9px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.sourceAirport}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center" alignSelf={"center"}>
          <Typography
            style={{
              fontSize: "11px",
              color: "#fff",
              fontWeight: "500",
            }}
          >
            {passengerDetails.journeyType}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "9px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.destinationAirport}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "11px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.arrivalTime}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center" alignSelf={"center"} />

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "11px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.departureTime}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "11px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.arrivalDate}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center" alignSelf={"center"} />

        <Grid item xs={3} textAlign="center">
          <Typography
            style={{
              fontSize: "11px",
              color: "#fff",
              fontWeight: "400",
              overflowWrap: "break-word",
            }}
          >
            {passengerDetails.departureDate}
          </Typography>
        </Grid>
      </Grid>
      <PassengerDetails />
      <ButtonFooter />
    </React.Fragment>
  );
}
