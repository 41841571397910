import queryString from "query-string";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { CssBaseline, CircularProgress } from "@mui/material";

import woman from "../assets/woman.png";
import { signInMember } from "../redux/slices/userSlice";

const useStyle = makeStyles(() => ({
  outerDivStyle: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: `linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%),url(${woman})`,
    mixBlendMode: "normal",
    height: "100vh",
  },
}));

const Wrapper = styled.div`
  height: 100vh;
  width: 100wv;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default function Layout() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [result, setResult] = useState();

  const pnr = queryString.parse(window.location.search).pnr;
  const userName = queryString.parse(window.location.search).userName;

  useEffect(() => {
    if (pnr && userName) {
      setResult(dispatch(signInMember({ pnr: pnr, userName: userName })));
    }
  }, [dispatch, userName, pnr]);

  if (!result) {
    return (
      <Fragment>
        <Wrapper>
          <CircularProgress color="primary" />
        </Wrapper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={classes.outerDivStyle}>
        <CssBaseline />

        <Outlet />
      </div>
    </Fragment>
  );
}
