import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, MenuItem, Typography } from "@mui/material";

import Aadhar from "./Aadhar";
import DrivingLicense from "./DrivingLisence";
import { useAxiosVerismart } from "../../hooks";
import TextField from "../../components/PrimaryTextField";
import { setDLData, setAadharData } from "../../redux/slices/documentSlice";

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

export default function DocumentSelection() {
  const dispatch = useDispatch();
  const [token, setToken] = useState("");

  const userIdentity = useSelector(
    (state) => state.userIdentityDetails.identityType
  );

  const [document, setDocument] = useState(userIdentity);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    relation: "",
  });

  const loginAxios = useAxiosVerismart({ baseURL: "aadharUrl" });

  useEffect(() => {
    loginAxios({
      url: "/login/",
      method: "POST",
      data: {
        client_secret: "dmVyaXNtYXJ0",
      },
    }).then((response) => {
      if (response.success) {
        setToken(response.access_token);
      }
    });
  }, [loginAxios]);

  function handleChangeValue(e) {
    setFormData((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
    localStorage.setItem(e.target.name, e.target.value);
  }

  function handleChange(e) {
    setDocument(e.target.value);
    if (e.target.value === "dl") {
      let payload = {
        identityType: "",
        dlNo: "",
        name: "",
        fathersName: "",
        dob: "",
        address: "",
      };
      dispatch(setDLData(payload));
    }
    if (e.target.value === "aadhaar") {
      let payload = {
        identityType: "",
        aadharNo: "",
        name: "",
        gender: "",
        dob: "",
        address: "",
      };
      dispatch(setAadharData(payload));
    }
  }

  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "18px",
              color: "#333",
            }}
          >
            Step 1 Of 4
          </Typography>

          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            Keep you aadhaar card / DL handy , you'll be needing it for
            uploading its picture.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChangeValue}
            name="relation"
            label="Relation"
            select
            value={formData.relation}
          >
            <MenuItem value="self">Self</MenuItem>
            <MenuItem value="mother">Mother</MenuItem>
            <MenuItem value="father">Father</MenuItem>
            <MenuItem value="sister">Sister</MenuItem>
            <MenuItem value="brother">Brother</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            value={document}
            select
            label="Select Document"
          >
            <MenuItem value="dl">DRIVING LICENSE</MenuItem>
            <MenuItem value="aadhaar">AADHAAR CARD</MenuItem>
          </TextField>
        </Grid>

        {document === "aadhaar" && <Aadhar token={token} />}
        {document === "dl" && (
          <DrivingLicense token={token} setToken={setToken} />
        )}
      </Grid>
    </Wrapper>
  );
}
