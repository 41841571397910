import React, { useState, useEffect } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { IoIosArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import pages from "../../constants/pages";
import { signInMember } from "../../redux/slices/userSlice";

const Wrapper = styled.div`
  display: flex;
  background: #f1f1f1;
  height: 134px;
`;

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
}));

export default function ButtonFooter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);

  const passengerDetails = useSelector((state) => state.passengerDetails);
  const pnr = queryString.parse(window.location.search).pnr;
  const userName = queryString.parse(window.location.search).userName;

  useEffect(() => {
    dispatch(signInMember({ pnr: pnr, userName: userName }));
  }, [dispatch, userName, pnr]);

  function handleClick() {
    navigate(pages.successPage.route + `?pnr=${pnr}&userName=${userName}`);
  }

  function handleChange(e) {
    setTerms(e.target.checked);
  }

  return (
    <Wrapper>
      <Grid container spacing={2} p="10px">
        <Grid item>
          <Typography
            style={{ fontSize: "16px", fontWeight: "400", color: "#b2b2b2" }}
          >
            Total Fare
          </Typography>
          <Typography
            style={{ fontSize: "17px", fontWeight: "500", color: "#333" }}
          >
            Rs. {passengerDetails.price}
          </Typography>
        </Grid>
        <Grid item xs textAlign={"end"} alignSelf="center">
          <Button
            className={classes.buttonStyle}
            endIcon={<IoIosArrowDropright />}
            disabled={!terms}
            onClick={handleClick}
            style={{
              color: terms ? "#fff" : "rgba(0, 0, 0, 0.26)",
              padding: "5px 35px",
              fontSize: "16px",
              background: terms
                ? "linear-gradient(180deg, #1976D2 0%, #01579B 100%)"
                : "rgba(0, 0, 0, 0.12)",
            }}
          >
            Pay & Check In
          </Button>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            classes={{
              label: classes.filterName,
            }}
            value={terms}
            control={
              <Checkbox
                value={terms}
                onChange={handleChange}
                sx={{
                  color: "#32cd32",
                  "&.Mui-checked": {
                    color: "#32cd32",
                  },
                }}
              />
            }
            label="I accept the company terms and conditions."
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
