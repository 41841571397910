import { Fragment } from "react";
import styled from "styled-components";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Edit, Search } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { AiOutlineUserAdd } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";

import { useAxios } from "../../hooks";
import TextField from "../../components/PrimaryTextField";
import { alert } from "../../redux/slices/alertSlice";
import DigiyatraIDDialog from "./dialog components/Dialog";

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const Card = styled(Paper)`
  background: #ffffff;
  flex-grow: 1;
  box-shadow: 0px 17px 80px rgba(0, 0, 0, 0.05),
    0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0359427),
    0px 3.79717px 17.869px rgba(0, 0, 0, 0.0298054),
    0px 2.12866px 10.0172px rgba(0, 0, 0, 0.025),
    0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0201946),
    0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0140573);
  border-radius: 10px;
`;

export default function FormDisplay({ formData, setFormData, pnr }) {
  const dispatch = useDispatch();

  const defaultProps = {
    options: pnr,
    getOptionLabel: (option) => option.label,
  };

  const [edit, setEdit] = useState(true);
  const [apiStatus, setApiStatus] = useState(true);
  const [passengers, setPassengers] = useState({});
  const [digiyatraDialog, setDigiyatraDialog] = useState({
    state: false,
    data: {},
    list: {},
  });

  const axios = useAxios({ baseURL: "webCheckIn" });

  function handleSearchPNR() {
    setEdit(false);
    axios({
      url: "/fetch_passengers_by_pnr?pnr=" + formData.pnr,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        dispatch(alert({ type: "success", message: response.message }));
        setPassengers(response.data);
        setApiStatus(true);
      } else {
        setApiStatus(false);
        setPassengers({
          pnr: formData.pnr,
          passengers: [],
        });
      }
    });
  }

  function handleChangePNR(event) {
    setFormData((prev) => ({
      ...prev,
      pnr: event.target.value,
    }));
  }

  function handleCardEdit(details, index) {
    if (passengers._id) {
      setDigiyatraDialog({
        state: true,
        data: details,
        index: index,
        list: passengers,
        stage: "update",
        edit: false,
      });
    } else {
      setDigiyatraDialog({
        state: true,
        data: details,
        index: index,
        list: passengers,
        stage: "correct",
        edit: true,
      });
    }
  }

  function addPassenger() {
    setDigiyatraDialog({
      state: true,
      list: passengers,
      stage: "add",
      edit: true,
      data: {
        name: "",
        age: "",
        gender: "",
        digiyatra_id: "",
      },
    });
  }

  function saveDetails() {
    axios({
      url: "/digiyatra_id",
      method: "PATCH",
      data: passengers,
    }).then((response) => {
      if (response.status) {
        setApiStatus(true);
        dispatch(alert({ type: "success", message: response.message }));
      }
    });
  }

  return (
    <Wrapper>
      <Grid container spacing={4} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            Search
          </Typography>
        </Grid>

        <Grid item xs={12} mt="10px">
          <Autocomplete
            freeSolo
            fullWidth
            disableClearable
            onChange={(event, newValue) => {
              if (newValue === null) {
                setFormData((prev) => ({
                  ...prev,
                  pnr: "",
                }));
              } else {
                setFormData((prev) => ({
                  ...prev,
                  pnr: newValue.id,
                }));
              }
            }}
            {...defaultProps}
            renderInput={(params) => (
              <TextField
                {...params}
                name="PNR"
                label="PNR"
                disabled={!edit}
                onChange={handleChangePNR}
                InputProps={{
                  ...params.InputProps,
                  endAdornment:
                    !!formData.pnr &&
                    (!edit ? (
                      <IconButton
                        color="primary"
                        onClick={() => setEdit(!edit)}
                      >
                        <Edit />
                      </IconButton>
                    ) : (
                      <IconButton color="primary" onClick={handleSearchPNR}>
                        <Search />
                      </IconButton>
                    )),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} />
        {passengers?.passengers?.length > 0 && (
          <React.Fragment>
            <Grid item xs={8}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                List of passengers on this PNR.
              </Typography>
            </Grid>

            {!apiStatus && (
              <Grid item xs={4} textAlign="end">
                <Tooltip>
                  <IconButton
                    onClick={addPassenger}
                    style={{ color: "white", background: "#1976d2" }}
                  >
                    <AiOutlineUserAdd />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            <Grid item xs={12} />

            {passengers?.passengers?.map((passenger, index) => (
              <Grid item xs={12} key={index}>
                <Card>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding: "10px",
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="caption1"
                        style={{ fontWeight: "bold" }}
                      >
                        Passenger {index + 1}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "end" }}>
                      <IconButton
                        style={{ padding: "0" }}
                        onClick={() => handleCardEdit(passenger, index)}
                      >
                        <Edit size="small" />
                      </IconButton>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography>Name : {passenger.name}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography>Age : {passenger.age}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography>
                        Digiyatra ID : {passenger.digiyatra_id}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography>Gender : {passenger.gender}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </React.Fragment>
        )}

        {!apiStatus && (
          <React.Fragment>
            {passengers?.passengers?.length === 0 && (
              <Fragment>
                <Grid item xs={12}>
                  <Typography>
                    Could not found the data,Please add passenger on the
                    searched PNR.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button onClick={addPassenger}>Add Passenger</Button>
                </Grid>
              </Fragment>
            )}

            {passengers?.passengers?.length > 0 && !apiStatus && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={saveDetails}
                >
                  Save Details
                </Button>
              </Grid>
            )}
          </React.Fragment>
        )}
        <DigiyatraIDDialog
          digiyatraDialog={digiyatraDialog}
          setDigiyatraDialog={setDigiyatraDialog}
          passengers={passengers}
          setPassengers={setPassengers}
        />
      </Grid>
    </Wrapper>
  );
}
