const pages = {
  //Dashboard Module
  home: {
    ID: "home",
    route: "/home",
  },

  login: {
    ID: "login",
    route: "/login",
  },

  loginBiometric: {
    ID: "loginBiometric",
    route: "/login-biometric",
  },

  signUp: {
    ID: "signup",
    route: "/sign-up",
  },

  flightDetails: {
    ID: "flightDetails",
    route: "/passenger-details",
  },

  successPage: {
    ID: "successPage",
    route: "/success",
  },

  seatingPage: {
    ID: "seatingPage",
    route: "/seating-preference",
  },

  documentPage: {
    ID: "documentPage",
    route: "/",
  },

  captureImagePage: {
    ID: "captureImagePage",
    route: "/capture-video",
  },

  previewVideoImage: {
    ID: "previewVideoImage",
    route: "/preview-video",
  },

  userDetailsPage: {
    ID: "userDetailsPage",
    route: "/identity-details",
  },

  successUserPage: {
    ID: "successUserPage",
    route: "/upload-success",
  },

  boardingPassPage: {
    ID: "boardingPassPage",
    route: "/upload-boarding-pass",
  },

  selectionPage: {
    ID: "selectionPage",
    route: "/home",
  },
  digiyatraIdPage: {
    ID: "digiyatraID",
    route: "/register-digiyatra-id",
  },
  uploadedIdsPage: {
    ID: "uploadedID",
    route: "/uploaded-ID",
  },
};

export default pages;
