import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { Save, CheckCircleRounded } from "@mui/icons-material";

import { useAxios } from "../../../hooks";
import TextField from "../../../components/PrimaryTextField";
import pages from "../../../constants/pages";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const InnerWrapper = styled(Grid)`
  background: #dcedc8;
  color: #8bc34a;
  padding: 10px 5px;
`;

export default function UserDetailsLicense() {
  const axios = useAxios();
  const classes = useStyles();
  const navigate = useNavigate();

  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDetails = useSelector((state) => state.userIdentityDetails);

  function handleChange(e) {
    setTerms(e.target.checked);
  }

  function handleNext() {
    setLoading(false);
    let userID = localStorage.getItem("user-id");
    // axios({
    //   url: "/registration-status",
    //   method: "POST",
    //   data: {
    //     user_id: userID,
    //     status: true,
    //   },
    // }).then((response) => {
    //   if (response.status) {
    //     localStorage.setItem("registration-status", true);
    //     navigate(pages.successUserPage.route);
    //   }
    // });
    localStorage.setItem("registration-status", true);
    var number = localStorage.getItem("phone-id");
    var relation = localStorage.getItem("relation");
    axios({
      url: "/create_user",
      method: "POST",
      data: {
        name: userDetails.name,
        doc_type: "dl",
        relation: relation,
        phone_number: number,
        unique_number: userDetails.dlNo,
        dob: userDetails.dob,
        address: userDetails.address,
      },
    });
    navigate(pages.successUserPage.route);
  }

  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "18px",
              color: "#333",
            }}
          >
            Step 4 Of 4
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InnerWrapper>
            <div style={{ display: "flex" }}>
              <CheckCircleRounded style={{ color: "#8bc34a" }} />
              &nbsp;
              <Typography style={{ fontSize: "15px", fontWeight: "400" }}>
                Video Uploaded Successfully.
              </Typography>
            </div>
            <Typography
              style={{
                paddingLeft: "5px",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              Your video was uploaded successfully in the system.
            </Typography>
          </InnerWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Driving License Number" value={userDetails.dlNo} />
        </Grid>

        <Grid item xs={12}>
          <TextField label="Name" value={userDetails.name} />
        </Grid>

        <Grid item xs={12}>
          <TextField label="DOB" value={userDetails.dob} />
        </Grid>

        <Grid item xs={12}>
          <TextField label="Address" value={userDetails.address} />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            classes={{
              label: classes.filterName,
            }}
            value={terms}
            control={
              <Checkbox
                value={terms}
                onChange={handleChange}
                sx={{
                  color: "#32cd32",
                  "&.Mui-checked": {
                    color: "#32cd32",
                  },
                }}
              />
            }
            label="I hereby allow the app to store my 
                information."
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            className={classes.buttonStyle}
            variant="contained"
            component="span"
            disabled={!terms}
            startIcon={<Save />}
            onClick={handleNext}
            loading={loading}
            fullWidth
            style={{
              padding: "5px 35px",
              fontSize: "16px",
            }}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
