import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

import pages from "../../constants/pages";
import { setDLData, setAadharData } from "../../redux/slices/documentSlice";
import UploadDocument from "./adhar components/UploadDocument";

export default function CaptureImage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useLocation();

  useEffect(() => {
    if (userData.state.userData) {
      if (userData.state.userData.identityType === "dl") {
        dispatch(setDLData(userData.state.userData));
      }
      if (userData.state.userData.identityType === "aadhaar") {
        dispatch(setAadharData(userData.state.userData));
      }
    }
  }, [dispatch, userData.state.userData]);

  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <ArrowBackIosNew
              style={{
                color: "#fff",
                fontSize: "35px",
                padding: "0px 8px 0 0",
              }}
              onClick={() =>
                navigate(pages.documentPage.route, {
                  state: { userData: userData.state.userData },
                })
              }
            />
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "26px",
                color: "#fff",
                lineHeight: "30px",
              }}
            >
              Capture Image
            </Typography>
          </div>
        </Grid>
      </Grid>
      <UploadDocument userData={userData} />
    </React.Fragment>
  );
}
