import _ from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components/macro";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DialogContent,
  Dialog,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";

import Form from "./Form";
import { useAxios } from "../../../hooks";
import { alert } from "../../../redux/slices/alertSlice";

const Card = styled(Dialog)`
  min-width: 360px;
  min-height: 240px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function DigiyatraIDDialog({
  digiyatraDialog,
  setDigiyatraDialog,
  setPassengers,
}) {
  const dispatch = useDispatch();
  const axios = useAxios({ baseURL: "webCheckIn" });

  const [data, setData] = useState({ state: false, data: "" });

  const handleClose = (e, reason) => {
    setDigiyatraDialog({ state: false, data: {} });
  };

  return (
    <React.Fragment>
      <Card open={digiyatraDialog.state} onClose={handleClose}>
        <Formik
          initialValues={digiyatraDialog.data}
          enableReinitialize
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={async (formData, { setSubmitting }) => {
            if (digiyatraDialog.stage === "update") {
              let data = digiyatraDialog.list;

              data.passengers[digiyatraDialog.index].digiyatra_id =
                formData.digiyatra_id;

              axios({
                url: "/digiyatra_id",
                method: "PATCH",
                data: data,
              }).then((response) => {
                if (response.status) {
                  dispatch(
                    alert({ type: "success", message: response.message })
                  );
                  handleClose();
                }
              });
            }
            if (digiyatraDialog.stage === "add") {
              setPassengers((prevVal) => ({
                ...prevVal,
                passengers: [
                  ...prevVal.passengers,
                  {
                    name: formData.name,
                    age: formData.age,
                    gender: formData.gender,
                    digiyatra_id: formData.digiyatra_id,
                  },
                ],
              }));
              handleClose();
            }
            if (digiyatraDialog.stage === "correct") {
              setPassengers((prevVal) => ({
                ...prevVal,
                passengers: setValue(prevVal.passengers),
              }));

              function setValue(values) {
                values[digiyatraDialog.index].digiyatra_id =
                  formData.digiyatra_id;
                values[digiyatraDialog.index].name = formData.name;
                values[digiyatraDialog.index].age = formData.age;
                values[digiyatraDialog.index].gender = formData.gender;

                return values;
              }
              handleClose();
            }
          }}
        >
          {(dialogFormikProps) => (
            <React.Fragment>
              <DialogContent>
                <Grid container spacing={4}>
                  <Form
                    data={data}
                    setData={setData}
                    digiyatraDialog={digiyatraDialog}
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </DialogContent>

              <DialogActions style={{ padding: "0 24px 20px" }}>
                <Grid container spacing={0}>
                  {!data.state ? (
                    <Fragment>
                      <Grid item>
                        <Button variant="outlined" onClick={handleClose}>
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item xs />

                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={dialogFormikProps.handleSubmit}
                          disabled={!dialogFormikProps.isValid}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setData({ state: false, data: "" });
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item xs />
                    </Fragment>
                  )}
                </Grid>
              </DialogActions>
            </React.Fragment>
          )}
        </Formik>
      </Card>
    </React.Fragment>
  );
}

const validationSchema = Yup.object().shape({
  digiyatra_id: Yup.string().required("This field can't be empty"),
});
