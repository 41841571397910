import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CameraAlt, CheckCircleRounded } from "@mui/icons-material";

import { useAxiosV2, useComponent } from "../../hooks";
import DrivingForm from "./adhar components/DrivingForm";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

const Wrapper = styled(Grid)`
  background: #dcedc8;
  color: #8bc34a;
  padding: 10px 5px;
`;

export default function DrivingLicense({ token }) {
  const axios = useAxiosV2();
  const classes = useStyles();
  const { alert } = useComponent();

  const [details, setDetails] = useState({
    dl_no: "",
    dob: "",
  });
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    firstUpload: true,
    recapture: false,
    drivingForm: false,
  });

  async function handleCapture(target) {
    const file = target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("ping", "0.0");
    setLoading(true);
    axios({
      url: "/documentdetails/",
      method: "POST",
      data: formData,
      authToken: token,
    }).then((response) => {
      if (response.response_code === 101) {
        alert.success("Details fetched successfully.");
        setLoading(false);
        setUploadStatus({
          firstUpload: false,
          recapture: true,
          drivingForm: true,
        });
        setDetails({
          dl_no: response.data.number,
          dob: new Date(),
        });
      } else {
        alert.error(
          "Failed to fetch the details, please upload correct picture."
        );
        setLoading(false);
        setUploadStatus({
          firstUpload: true,
          recapture: false,
          drivingForm: false,
        });
      }
    });
  }

  return (
    <React.Fragment>
      {uploadStatus.firstUpload && (
        <Grid item xs={12}>
          <label htmlFor="contained-button-file">
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              capture="environment"
              style={{ display: "none" }}
              onChange={(e) => handleCapture(e.target)}
            />
            <LoadingButton
              className={classes.buttonStyle}
              variant="contained"
              component="span"
              startIcon={<CameraAlt />}
              loading={loading}
              fullWidth
              style={{
                padding: "5px 35px",
                fontSize: "16px",
              }}
            >
              Upload Driving License
            </LoadingButton>
          </label>
        </Grid>
      )}

      {uploadStatus.recapture && (
        <React.Fragment>
          <Grid item xs={12}>
            <Wrapper>
              <div style={{ display: "flex" }}>
                <CheckCircleRounded style={{ color: "#8bc34a" }} />
                &nbsp;
                <Typography style={{ fontSize: "15px", fontWeight: "400" }}>
                  Image Uploaded Successfully.
                </Typography>
              </div>
              <Typography
                style={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                Your ID image was uploaded successfully in the system.
              </Typography>
            </Wrapper>
          </Grid>

          <Grid item xs={12}>
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleCapture(e.target)}
              />
              <LoadingButton
                className={classes.buttonStyle}
                variant="contained"
                component="span"
                startIcon={<CameraAlt />}
                loading={loading}
                fullWidth
                style={{
                  padding: "5px 35px",
                  fontSize: "16px",
                }}
              >
                Recapture Driving License
              </LoadingButton>
            </label>
          </Grid>
        </React.Fragment>
      )}

      {uploadStatus.drivingForm && (
        <DrivingForm
          details={details}
          token={token}
          setDetails={setDetails}
          setUploadStatus={setUploadStatus}
        />
      )}
    </React.Fragment>
  );
}
