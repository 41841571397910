/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
import Layout from "../../layout/Layout";
import SuccessPage from "../../pages/success components/SuccessPage";
import FlightDetail from "../../pages/details components/FlightDetails";
import SeatingPreference from "../../pages/seating components/SeatingPreference";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

const pwaRoutes = {
  //Main Module Routes
  passengerDetailsRoute: {
    ID: pages.flightDetails.ID,
    path: pages.flightDetails.route,
    layout: Layout,
    element: FlightDetail,
  },

  seatingRoute: {
    ID: pages.seatingPage.ID,
    path: pages.seatingPage.route,
    layout: Layout,
    element: SeatingPreference,
  },

  successRoute: {
    ID: pages.successPage.ID,
    path: pages.successPage.route,
    layout: Layout,
    element: SuccessPage,
  },
};

export default pwaRoutes;
