/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
import SuccessPage from "../../pages/documents components/SuccessPage";
import Home from "../../pages/home page components/Home";
import SignInBiometric from "../../pages/login biometric components/Login";
import SignIn from "../../pages/sign in components/SignIn";
import SignUp from "../../pages/sign up components/SignUp";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

const primaryRoutes = {
  //Main Module Routes
  homeRoute: {
    ID: pages.home.ID,
    path: pages.home.route,
    element: Home,
  },

  loginRoute: {
    ID: pages.login.ID,
    path: pages.login.route,
    element: SignIn,
  },

  signBiometricRoute: {
    ID: pages.loginBiometric.ID,
    path: pages.loginBiometric.route,
    element: SignInBiometric,
  },

  signupRoute: {
    ID: pages.signUp.ID,
    path: pages.signUp.route,
    element: SignUp,
  },

  successRoute: {
    ID: pages.successUserPage.ID,
    path: pages.successUserPage.route,
    element: SuccessPage,
  },
};

export default primaryRoutes;
