import { createSlice } from "@reduxjs/toolkit";

const loaderInitialState = {
  loading: false,
  apiCount: 0,
  error: false,
  traceID: "",
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState: loaderInitialState,
  reducers: {
    startLoader: (_, { payload = 1 }) => ({
      loading: true,
      apiCount: payload,
      error: false,
      traceID: "",
    }),

    stopLoader: (state) => ({
      ...state,
      loading: false,
      apiCount: 0,
      error: false,
    }),

    apiComplete: (state, { payload = 1 }) => ({
      ...state,
      apiCount: state.apiCount - payload,
      loading: !!(state.apiCount - payload > 0),
    }),

    apiError: (state, { payload = "" }) => ({
      ...state,
      loading: false,
      error: true,
      traceID: payload,
    }),
  },
});

export const { startLoader, stopLoader, apiComplete, apiError } =
  loaderSlice.actions;

export default loaderSlice.reducer;
