import { sha256 } from "js-sha256";
import styled from "styled-components";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CameraAlt, CheckCircleRounded } from "@mui/icons-material";

import { useAxiosV2, useComponent } from "../../hooks";
import AadharForm from "./adhar components/AdharForm";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

const Wrapper = styled(Grid)`
  background: #dcedc8;
  color: #8bc34a;
  padding: 10px 5px;
`;

export default function Aadhar({ token }) {
  const classes = useStyles();
  const { alert } = useComponent();
  const userIdentity = useSelector(
    (state) => state.userIdentityDetails.identityType
  );

  const [captcha, setCaptcha] = useState({
    captcha: "",
    uuid: "",
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [otpStatus, setOtpStatus] = useState(
    userIdentity === "" ? false : true
  );
  const [sendFirstOtp, setFirstOtp] = useState(
    userIdentity === "" ? false : true
  );
  const [uploadStatus, setUploadStatus] = useState(false);

  const axios = useAxiosV2({ baseURL: "aadharUrl" });

  async function handleCapture(target) {
    var requestID = "";
    while (requestID.length < 16)
      requestID += Math.random().toString(36).substring(2);
    requestID = requestID.substring(0, 16);
    var hashID = sha256(`META5488|${requestID}|KijfgGFw3eMeta|PassMeta`);
    const file = target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("ping", "0.0");
    setLoading(true);
    setCaptcha((prevVal) => ({
      ...prevVal,
      loading: true,
    }));

    axios({
      url: "/documentdetails/",
      method: "POST",
      data: formData,
      authToken: token,
    }).then((res) => {
      if (res.response_code === 101) {
        alert.success("Details fetched successfully.");
        setUploadStatus(true);
        setDetails({
          adhar_number: res.data.number,
        });
        setLoading(false);

        axios({
          url: "/get/captcha/",
          method: "POST",
          data: {
            headers: {
              client_code: "META5488",
              sub_client_code: "META5488",
              channel_code: "web",
              channel_version: "1",
              stan: "234fdfgb",
              client_ip: "",
              transmission_datetime: "1624812328201",
              operation_mode: "SELF",
              run_mode: "",
              actor_type: "DEFAULT",
              user_handle_type: "EMAIL",
              user_handle_value: "abc@gmail.com",
              function_code: "DEFAULT",
              function_sub_code: "DEFAULT",
            },
            request: {
              api_key: "h73j93ja092",
              request_id: requestID,
              hash: hashID,
            },
          },
          authToken: token,
        }).then((response) => {
          if (response.response_code === 101) {
            setCaptcha({
              captcha: response.data.response_data.captcha,
              uuid: response.data.response_data.uuid,
              loading: false,
            });
          } else {
            alert.error("Error in loading captcha, please request a new one.");
            setCaptcha({
              captcha: "",
              uuid: "",
              loading: true,
            });
          }
        });
      } else {
        alert.error(
          "Failed to fetch the details, please upload correct picture."
        );
        setUploadStatus(false);
        setLoading(false);
      }
    });
  }

  return !uploadStatus ? (
    <React.Fragment>
      <Grid item xs={12}>
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            capture="environment"
            style={{ display: "none" }}
            onChange={(e) => handleCapture(e.target)}
          />
          <LoadingButton
            className={classes.buttonStyle}
            variant="contained"
            component="span"
            startIcon={<CameraAlt />}
            loading={loading}
            fullWidth
            style={{
              padding: "5px 35px",
              fontSize: "16px",
            }}
          >
            Upload Aadhar Card
          </LoadingButton>
        </label>
      </Grid>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {!sendFirstOtp && (
        <React.Fragment>
          <Grid item xs={12}>
            <Wrapper>
              <div style={{ display: "flex" }}>
                <CheckCircleRounded style={{ color: "#8bc34a" }} />
                &nbsp;
                <Typography style={{ fontSize: "15px", fontWeight: "400" }}>
                  Image Uploaded Successfully.
                </Typography>
              </div>
              <Typography
                style={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                Your ID image was uploaded successfully in the system.
              </Typography>
            </Wrapper>
          </Grid>

          <Grid item xs={12}>
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleCapture(e.target)}
              />
              <LoadingButton
                className={classes.buttonStyle}
                variant="contained"
                component="span"
                startIcon={<CameraAlt />}
                loading={loading}
                fullWidth
                style={{
                  padding: "5px 35px",
                  fontSize: "16px",
                }}
              >
                Recapture Aadhaar Card
              </LoadingButton>
            </label>
          </Grid>
        </React.Fragment>
      )}

      <AadharForm
        otpStatus={otpStatus}
        setOtpStatus={setOtpStatus}
        sendFirstOtp={sendFirstOtp}
        setFirstOtp={setFirstOtp}
        captcha={captcha}
        setCaptcha={setCaptcha}
        token={token}
        details={details}
        setDetails={setDetails}
      />
    </React.Fragment>
  );
}
