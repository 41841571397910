import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import woman from "../../assets/woman.png";
import { useComponent } from "../../hooks";
import { TextFieldWithIcon, TextField } from "../../components";

const useStyle = makeStyles(() => ({
  welcomeTypography: {
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "38px",
    color: "#FFFFFF",
  },

  welcomeDiv: {
    textAlign: "center",
    alignSelf: "center",
  },

  outerDivStyle: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundImage: `url(${woman})`,
    mixBlendMode: "normal",
  },

  innerDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background:
      "linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%)",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function FormDisplay() {
  const classes = useStyle();
  const navigate = useNavigate();
  const { alert } = useComponent();
  const axios = useAxios();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function handleMobileChange(e) {
    setUserName(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handleSignup() {
    function validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
    var data = {};
    const email = validateEmail(userName);
    if (email) {
      data.email = userName;
      data.password = password;
    } else {
      data.mobile_no = userName;
      data.password = password;
    }
    axios({
      url: "/sign-up",
      method: "POST",
      data: data,
    }).then((response) => {
      if (response.status) {
        localStorage.setItem("user-id", response.data.member_id);
        localStorage.setItem("user-name", response.data.username);
        localStorage.setItem("registration-status", false);
        navigate(pages.documentPage.route);
      }
    });
  }

  function handleLogin() {
    navigate(pages.loginBiometric.route);
  }

  return (
    <div className={classes.outerDivStyle}>
      <div className={classes.innerDivStyle}>
        <Grid container spacing={4} p="20px">
          <Grid item xs={12} className={classes.welcomeDiv}>
            <Typography variant="h3" className={classes.welcomeTypography}>
              WELCOME USER
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextField
              fullWidth
              label="Mobile Number/Email"
              name="mobile"
              value={userName}
              onChange={handleMobileChange}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextFieldWithIcon
              fullWidth
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              value={password}
              onChange={handlePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!password)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              style={{ color: "#fff", padding: "10px", fontSize: "18px" }}
              className={classes.buttonStyle}
              fullWidth
              onClick={handleSignup}
              endIcon={<IoIosArrowDropright />}
            >
              Next
            </Button>
          </Grid>

          <Grid item xs={12} textAlign="start">
            <Typography variant="caption1" style={{ color: "#fff" }}>
              Already have account?{" "}
              <Typography
                style={{ cursor: "pointer" }}
                variant="button"
                onClick={handleLogin}
              >
                Login!
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
