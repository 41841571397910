import React from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Typography, Divider, Breadcrumbs, Grid, Button } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";

import styled from "styled-components/macro";

const Link = styled(RouterLink)`
  text-decoration: none;
  color: #407ad6;
`;

export default function PageHeader({
  title = "",
  pageName,
  breadcrumbs = [],
  addIcon = [],
  editIcon = [],
  buttons = [],
}) {
  const [buttonTitle, URL, createPermission] = addIcon;
  const [editState, setEditState, updatePermission] = editIcon;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title={title || pageName} />

      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h3">{pageName}</Typography>

          <Breadcrumbs>
            <Link to="/">Home</Link>

            {breadcrumbs.length > 0 &&
              breadcrumbs.map((breadcrumb, index) => (
                <Link key={index} to={breadcrumb.location}>
                  {breadcrumb.name}
                </Link>
              ))}

            <Typography>{pageName}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid item key={index}>
                {icon}
              </Grid>
            ))}

          {createPermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => navigate(URL)}
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}

          {!editState && updatePermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={() => setEditState((prevValue) => !prevValue)}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginBlock: "14px" }}>
          <Divider />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
