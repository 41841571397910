import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";
import styled from "styled-components";

const Avatar = styled(MuiAvatar)`
  height: 35px;
  width: 35px;
  cursor: pointer;
  background: #d3cccc33;
`;

function AvatarButton({ children, ...props }, ref) {
  return (
    <Avatar ref={ref} {...props}>
      {children}
    </Avatar>
  );
}

export default React.forwardRef(AvatarButton);
