import axios from "axios";

import BASE_URL from "../../../constants/baseURL";

export default async function token() {
  const axiosInstance = axios.create({
    baseURL: BASE_URL.aadharUrl.url + BASE_URL.aadharUrl.version,
    headers: {
      Authorization: "Bearer",
      "Content-Type": "application/json",
    },
  });

  const token = await axiosInstance({
    url: "/login/",
    method: "POST",
    data: {
      client_secret: "dmVyaXNtYXJ0",
    },
  }).then((response) => response.data);

  return {
    authToken: token.access_token,
  };
}
