import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const BASE_URL = {
  webCheckIn: {
    url: "https://dev.backend-kyc-dashboard.metawing.ai/web-check-in",
    version: "",
  },

  biometricUrl: {
    url: "https://backend-poc.metawing.ai/digi-yatra-biometric",
    version: "",
  },

  aadharUrl: {
    url: "https://govtapi.kycvideo.in/api/",
    version: "v1",
  },
};

export default BASE_URL;
