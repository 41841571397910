import signIn from "./helper function/signIn";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const signInMember = createAsyncThunk("member/signIn", signIn);

const initialState = {
  pnrNo: "",
  flightNo: "",
  flightName: "",
  source: "",
  destination: "",
  sourceAirport: "",
  destinationAirport: "",
  arrivalDatetime: "",
  departureDatetime: "",
  journeyType: "",
  passengerDetails: [],
  price: "",
};

const counterSlice = createSlice({
  name: "passengerDetails",
  initialState,
  reducers: {
    seatChange(state, { payload }) {
      state.price = payload;
    },
  },

  extraReducers: {
    [signInMember.fulfilled]: (_, { payload }) => {
      return payload;
    },
  },
});

export const { seatChange } = counterSlice.actions;
export default counterSlice.reducer;
