import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";

import List from "./List";
import pages from "../../constants/pages";

export default function UploadedID() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container spacing={4} p="10px">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <ArrowBackIosNew
              style={{
                color: "#fff",
                fontSize: "35px",
                padding: "0px 8px 0 0",
              }}
              onClick={() => navigate(pages.home.route)}
            />
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "26px",
                color: "#fff",
                lineHeight: "30px",
              }}
            >
              Uploaded ID
            </Typography>
          </div>
        </Grid>
      </Grid>

      <List />
    </React.Fragment>
  );
}
