import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Event } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton, MobileDatePicker } from "@mui/lab";
import { Grid, Button, InputAdornment } from "@mui/material";

import pages from "../../../constants/pages";
import { useAxiosV2, useComponent } from "../../../hooks";
import TextField from "../../../components/PrimaryTextField";
import { setDLData } from "../../../redux/slices/documentSlice";
import convertNormalDate from "../../../utils/convertNormalDate";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

export default function DrivingForm({
  details,
  token,
  setDetails,
  setUploadStatus,
}) {
  const axios = useAxiosV2();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDetails = useSelector((state) => state.userIdentityDetails);

  function handleIdentity() {
    navigate(pages.captureImagePage.route, {
      state: { userData: userDetails },
    });
  }

  function handleChange(e) {
    if (e.target.name === "dl") {
      setDetails((val) => ({
        ...val,
        dl_no: e.target.value,
      }));
    }
  }

  function handleDate(value) {
    setDetails((val) => ({
      ...val,
      dob: value,
    }));
  }

  function confirmDetails() {
    setLoading(true);
    axios({
      url: "/verify/dl/",
      method: "POST",
      authToken: token,
      data: {
        dl_no: details.dl_no,
        dob: convertNormalDate(details.dob),
        consent: "Y",
        consent_text: "Hello This is Metawing ",
      },
    }).then((response) => {
      if (response.response_code === "101") {
        alert.success("Details fetched successfully.");
        setConfirm(true);
        setLoading(false);
        setUploadStatus({
          firstUpload: false,
          recapture: false,
          drivingForm: true,
        });
        var payload = {
          identityType: "dl",
          dlNo: response.result.dlNumber,
          name: response.result.name,
          dob: response.result.dob,
          address:
            response.result?.address[0]?.completeAddress ||
            "" + response.result?.address[0]?.state ||
            "" + response.result?.address[0]?.pin,
        };
        dispatch(setDLData(payload));
      } else {
        setLoading(false);
        setConfirm(false);
        alert.error("Details not found, please enter the correct details.");
      }
    });
  }

  return !confirm ? (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          onChange={handleChange}
          label="Driving License Number"
          name="dl"
          value={details.dl_no}
        />
      </Grid>

      <Grid item xs={12}>
        <MobileDatePicker
          name="dob"
          label="Date of birth"
          inputFormat="dd/MM/yyyy"
          value={details.dob}
          onChange={(value) => handleDate(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              name={"dob"}
              value={details.dob}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Event />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          className={classes.buttonStyle}
          loading={loading}
          variant="contained"
          component="span"
          fullWidth
          onClick={confirmDetails}
          endIcon={<IoIosArrowDropright />}
          style={{
            padding: "5px 35px",
            fontSize: "16px",
          }}
        >
          Confirm Details
        </LoadingButton>
      </Grid>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField label="Driving License Number" value={userDetails.dlNo} />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Name" value={userDetails.name} />
      </Grid>

      <Grid item xs={12}>
        <TextField label="DOB" value={userDetails.dob} />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Address" value={userDetails.address} />
      </Grid>

      <Grid item xs={12}>
        <Button
          className={classes.buttonStyle}
          variant="contained"
          component="span"
          fullWidth
          onClick={handleIdentity}
          endIcon={<IoIosArrowDropright />}
          style={{
            padding: "5px 35px",
            fontSize: "16px",
          }}
        >
          Proceed Next
        </Button>
      </Grid>
    </React.Fragment>
  );
}
