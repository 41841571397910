import axios from "axios";

import BASE_URL from "../../../constants/baseURL";
import convertDate from "../../../pages/details components/convertDate";

export default async function signIn({ pnr, userName }) {
  const axiosInstance = axios.create({
    baseURL: BASE_URL.webCheckIn.url + BASE_URL.webCheckIn.version,
    headers: {
      Authorization: "Bearer",
      "Content-Type": "application/json",
    },
  });

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const email = validateEmail(userName);
  var data = {};

  if (email) {
    data.pnr_no = pnr;
    data.email_id = userName;
  } else {
    data.pnr_no = pnr;
    data.last_name = userName;
  }

  const passengerDetails = await axiosInstance({
    url: "/passenger-details",
    method: "POST",
    data: data,
  }).then((response) => response.data.data);

  return {
    pnrNo: passengerDetails.pnr_no,
    userName: userName,
    flightNo: passengerDetails.flight_no,
    flightName: passengerDetails.flight_name,
    source: passengerDetails.source,
    destination: passengerDetails.destination,
    sourceAirport: passengerDetails.source_airport,
    destinationAirport: passengerDetails.destination_airport,
    sourceCode: passengerDetails.source_code,
    destinationCode: passengerDetails.destination_code,
    arrivalDate: convertDate(passengerDetails.arrival_date),
    departureDate: convertDate(passengerDetails.departure_date),
    arrivalTime: passengerDetails.arrival_time,
    departureTime: passengerDetails.departure_time,
    journeyType: passengerDetails.journey_type,
    totalHours: passengerDetails.total_hours,
    passengerDetails: passengerDetails.passenger_details,
    price: 0,
  };
}
