import React from "react";
import { Grid, Typography } from "@mui/material";

import BoardingPassImage from "./BoardingPassImage";

export default function BoardingPass() {
  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            Upload Boarding Pass
          </Typography>
        </Grid>
      </Grid>
      <BoardingPassImage />
    </React.Fragment>
  );
}
