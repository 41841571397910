import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { CssBaseline } from "@mui/material";

import woman from "../assets/woman.png";
import { useDispatch } from "react-redux";

const useStyle = makeStyles(() => ({
  outerDivStyle: {
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    background: `linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%),url(${woman})`,
    mixBlendMode: "normal",
    height: "100vh",
  },
}));

export default function DigiYatra() {
  const classes = useStyle();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={classes.outerDivStyle}>
        <CssBaseline />

        <Outlet />
      </div>
    </Fragment>
  );
}
