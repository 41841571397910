import AWS from "aws-sdk";
import forge from "node-forge";
import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { CameraAlt } from "@mui/icons-material";
import { Grid, Typography, Avatar, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAxios, useAlert } from "../../hooks";
import PastBoardingPass from "./PastBoardingPass";

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const Preview = styled.img`
  width: 100%;
  height: 300px;
`;

const useStyle = makeStyles(() => ({
  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function BoardingPassImage() {
  const axios = useAxios();
  const { alert } = useAlert();
  const classes = useStyle();
  const navigate = useNavigate();

  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileUrl, setProfileUrl] = useState("");

  let userID = localStorage.getItem("user-id");

  useEffect(() => {
    let encryptedData = Cookies.get("image-data");

    axios({
      url: "/private-key/" + userID,
    }).then(async (response) => {
      if (response.status) {
        const privateKey = forge.pki.privateKeyFromPem(
          response.data.private_key
        );
        setProfileUrl(
          privateKey.decrypt(forge.util.decode64(encryptedData), "RSA-OAEP", {
            md: forge.md.sha1.create(),
            mgf1: {
              md: forge.md.sha1.create(),
            },
          })
        );
      }
    });
  }, [userID, axios]);

  function handleClick(file) {
    var files = file.files[0];

    setLoading(true);
    var bucketName = "dc-chat-media";
    var bucketRegion = "ap-south-1";
    var identityPoolID = "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc";

    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolID,
      }),
    });
    let extension = files.name.split(".").pop();
    var filePath = "boarding-pass/" + parseInt(new Date().getTime());

    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: filePath,
        Body: files,
        ContentType: `image/${extension}`,
        ACL: "public-read",
      },
    });

    var promise = upload.promise();

    promise.then(
      function (data) {
        setImageURL(data.Location);
        setLoading(false);
      },
      function (err) {
        return alert("There was an error uploading your photo: ", err.message);
      }
    );
  }

  function sendData() {
    setImageURL("");
    alert.success("data sent successfully");
  }

  function downloadImage() {
    window.location.href = profileUrl;
  }

  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={8} pt={"0"}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            Past Uploaded Boarding Passes
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          pt="0"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Tooltip title="Download Image">
            <Avatar
              alt="Remy Sharp"
              style={{
                cursor: "pointer",
              }}
              src={profileUrl}
              sx={{ width: 50, height: 50 }}
              onClick={downloadImage}
            />
          </Tooltip>
        </Grid>

        <PastBoardingPass />

        {imageURL === "" ? (
          <Grid item xs={12} textAlign="center">
            <label htmlFor="contained-image-file">
              <input
                accept="image/*"
                id="contained-image-file"
                type="file"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleClick(e.target)}
              />
              <LoadingButton
                className={classes.buttonStyle}
                variant="contained"
                component="span"
                startIcon={<CameraAlt />}
                loading={loading}
                fullWidth
                style={{
                  padding: "5px 35px",
                  fontSize: "16px",
                }}
              >
                Upload Boarding Pass
              </LoadingButton>
            </label>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={12}>
              <Preview src={imageURL} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingButton
                fullWidth
                className={classes.buttonStyle}
                variant="contained"
                onClick={sendData}
                loading={loading}
              >
                Send Data
              </LoadingButton>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Wrapper>
  );
}
