import { Outlet } from "react-router-dom";
import { Fragment } from "react";
import { CssBaseline } from "@mui/material";

export default function PrimaryLayout() {
  return (
    <Fragment>
      <CssBaseline />

      <Outlet />
    </Fragment>
  );
}
