import React from "react";
import { useState, useEffect } from "react";
import { BsDot } from "react-icons/bs";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import VideoRecorder from "react-video-recorder";
import { Grid, Typography, Button } from "@mui/material";

import "./style.css";
import pages from "../../../constants/pages";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const SubHeading = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #808080;
`;

const CaptureImage = styled(Button)`
  border-radius: 5px;
  color: #fff;
  background: #ee1c25;
`;

const TermTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  align-self: center;
`;

export default function UploadDocument({ userData }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [startRecording, setStartRecording] = useState(false);

  const userDetails = useSelector((state) => state.userIdentityDetails);

  const [mimeType, setMimeType] = useState("video/webm;codecs=vp8,opus");

  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else if (window.navigator.userAgent.indexOf("Mac OS") !== -1) {
      setMimeType("video/mp4");
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else {
      setMimeType("video/webm;codecs=vp8,opus");
    }
  }, []);

  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "18px",
              color: "#333",
            }}
          >
            Step 2 Of 4
          </Typography>

          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            Click your image to match with the image in{" "}
            {userDetails.identityType}.
          </Typography>
        </Grid>
        {!startRecording ? (
          <React.Fragment>
            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  Hi {userDetails.name}, before starting the face verification
                  process please read the following guidelines
                </TermTypography>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  Please be ready with stable internet connectivity.
                </TermTypography>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  Allow us to use camera, microphone and location when asked.
                </TermTypography>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  Please try to record the video in a well lite room and with a
                  white background.
                </TermTypography>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  While doing the process video will be recorded for liveliness
                  and snapshots will taken to do face recognition.
                </TermTypography>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "5px", alignSelf: "center" }}
            >
              <div style={{ display: "flex" }}>
                <BsDot fontSize="large" style={{ marginRight: "10px" }} />
                <TermTypography>
                  If the recorded video is not clear or you think it is not
                  correct you record it and then submit it.
                </TermTypography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <CaptureImage
                fullWidth
                variant="contained"
                onClick={() => setStartRecording(true)}
                className={classes.buttonStyle}
              >
                Launch Camera
              </CaptureImage>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12}>
              <VideoRecorder
                isOnInitially
                countdownTime={0}
                timeLimit={7000}
                mimeType={mimeType}
                renderVideoInputView={({ videoInput }) => <>{videoInput}</>}
                constraints={{
                  audio: true,
                  video: {
                    width: { exact: 480, ideal: 480 },
                    height: { exact: 700, ideal: 700 },
                    aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                  },
                }}
                onRecordingComplete={(videoBlob) => {
                  navigate(pages.previewVideoImage.route, {
                    state: {
                      blob: videoBlob,
                      userData: userData?.state?.userData,
                    },
                  });
                }}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Wrapper>
  );
}
