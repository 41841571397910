import {
  TextField as MuiTextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import styled from "styled-components/macro";
import { get } from "lodash";

const TextFieldWithCSS = styled(MuiTextField)`
  .MuiFilledInput-input {
    background-color: rgba(255, 255, 255, 0.6);
    border: 0;
    border-radius: 4px;
  }

  &:hover .MuiFilledInput-input {
    border-bottom: 0;
  }

  MuiFilledInput-input:focus-within {
    background-color: rgba(255, 255, 255, 0.6);
    border: 0;
  }

  MuiFilledInput-input:after {
    border-bottom: 0;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

export default function TextField({ formikProps, ...props }) {
  if (!!formikProps) {
    return (
      <TextFieldWithCSS
        onChange={formikProps.handleChange}
        error={Boolean(
          get(formikProps.touched, props.name) &&
            get(formikProps.errors, props.name)
        )}
        helperText={
          get(formikProps.touched, props.name) &&
          get(formikProps.errors, props.name)
        }
        onBlur={formikProps.handleBlur}
        value={get(formikProps.values, props.name, "")}
        variant="filled"
        {...props}
      />
    );
  }

  return <TextFieldWithCSS variant="filled" fullWidth {...props} />;
}
