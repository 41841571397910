import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import woman from "../../assets/woman.png";
import { seatChange } from "../../redux/slices/userSlice";
import ButtonFooter from "../details components/ButtonFooter";

const useStyle = makeStyles(() => ({
  desc: {
    fontFamily: "'Roboto',sans-serif",
    fontStyle: "normal",
    fontWeight: "500!important",
    fontSize: "22px",
    lineHeight: "26px",
    color: "#FFFFFF",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "26px",
    color: "#fff",
    fontFamily: "'Roboto',sans-serif",
    lineHeight: "30px",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

const Wrapper = styled.div`
  background: #fff;
  height: calc(100vh - 295px);
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;
`;

const Seat = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: #b2b2b2;
  color: #333;
  border-radius: 5px 5px 0 0;
`;

export default function SeatingPreference() {
  const axios = useAxios();
  const classes = useStyle();
  const details = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState("");

  const passengerDetails = useSelector((state) => state.passengerDetails);

  useEffect(() => {
    if (passengerDetails.pnrNo) {
      axios({
        url: "/seat-details",
        method: "POST",
        data: {
          pnr: passengerDetails.pnrNo,
        },
      }).then((response) => {
        if (response.status) {
          setSeats(response.data.seats);
        }
      });
    }
  }, [axios, passengerDetails.pnrNo]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ height: "150px" }}>
          <Grid container spacing={2} p="10px">
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <ArrowBackIosNew
                  style={{
                    color: "#fff",
                    fontSize: "35px",
                    padding: "0px 8px 0 0",
                  }}
                  onClick={() =>
                    navigate(
                      pages.flightDetails.route +
                        "?pnr=" +
                        passengerDetails.pnrNo +
                        "&userName=" +
                        passengerDetails.userName
                    )
                  }
                />
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "26px",
                    color: "#fff",
                    lineHeight: "30px",
                  }}
                >
                  Seating Preference
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} mt={"30px"} />

            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#fff",
                  lineHeight: "30px",
                }}
              >
                {details.state.details.name +
                  " " +
                  details.state.details.last_name}
              </Typography>

              <Typography
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  color: "#fff",
                  lineHeight: "16px",
                }}
              >
                {details.state.details.gender + " " + details.state.details.age}
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="end">
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#fff",
                  lineHeight: "30px",
                }}
              >
                {details.state.details.seat_no}
              </Typography>

              <Typography
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  color: "#fff",
                  lineHeight: "16px",
                }}
              >
                Current Seat
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt="15px">
          <Wrapper>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Seat type with charges
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#b2b2b2",
                      borderRadius: "50%",
                      marginRight: "5px",
                      marginTop: "4px",
                    }}
                  />
                  <Typography style={{ color: "#b2b2b2" }}>
                    Occupied Seats
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={4}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#6CB4EE",
                      borderRadius: "50%",
                      marginRight: "5px",
                      marginTop: "4px",
                    }}
                  />
                  <Typography style={{ color: "#b2b2b2" }}>
                    Premium Seats
                  </Typography>
                </div>
                <Typography style={{ color: "#333", fontSize: "14px" }}>
                  ₹ 700
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      background: "#32cd32",
                      borderRadius: "50%",
                      marginRight: "5px",
                      marginTop: "4px",
                    }}
                  />
                  <Typography style={{ color: "#b2b2b2" }}>
                    Regular Seats
                  </Typography>
                </div>
                <Typography style={{ color: "#333", fontSize: "14px" }}>
                  Free
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt="20px"
                style={{ borderTop: "0.5px solid #cbcbcb", paddingRight: "0" }}
              />

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A1" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A1");
                            dispatch(seatChange(0));
                          }}
                        >
                          A1
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A2" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A2");
                            dispatch(seatChange(0));
                          }}
                        >
                          A2
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A3" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A3");

                            dispatch(seatChange(700));
                          }}
                        >
                          A3
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A7
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A8" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A8");
                            dispatch(seatChange(700));
                          }}
                        >
                          A8
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A9" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A9");
                            dispatch(seatChange(700));
                          }}
                        >
                          A9
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A13" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A13");
                            dispatch(seatChange(0));
                          }}
                        >
                          A13
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A14
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                        >
                          A15
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A19
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A20" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A20");
                            dispatch(seatChange(0));
                          }}
                        >
                          A20
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A21
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A25" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A25");

                            dispatch(seatChange(700));
                          }}
                        >
                          A25
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A26" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A26");
                            dispatch(seatChange(0));
                          }}
                        >
                          A26
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A27
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                        >
                          A31
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A32
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A33" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A33");
                            dispatch(seatChange(700));
                          }}
                        >
                          A33
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A37
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A38" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A38");
                            dispatch(seatChange(700));
                          }}
                        >
                          A38
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A39" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A39");
                            dispatch(seatChange(0));
                          }}
                        >
                          A39
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A40
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A41" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A41");
                            dispatch(seatChange(700));
                          }}
                        >
                          A41
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A42" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A42");
                            dispatch(seatChange(0));
                          }}
                        >
                          A42
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A43
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A44" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A44");
                            dispatch(seatChange(700));
                          }}
                        >
                          A44
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A45" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A45");
                            dispatch(seatChange(0));
                          }}
                        >
                          A45
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A46
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A50" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A50");
                            dispatch(seatChange(700));
                          }}
                        >
                          A50
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A51" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A51");
                            dispatch(seatChange(700));
                          }}
                        >
                          A51
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A52
                        </Seat>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A4
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A5
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A6" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A6");
                            dispatch(seatChange(0));
                          }}
                        >
                          A6
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A10" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A10");
                            dispatch(seatChange(700));
                          }}
                        >
                          A10
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A11
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A12" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A12");
                            dispatch(seatChange(0));
                          }}
                        >
                          A12
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A16
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A17
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A18" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A18");
                            dispatch(seatChange(0));
                          }}
                        >
                          A18
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A22" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A22");
                            dispatch(seatChange(700));
                          }}
                        >
                          A22
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A23
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A24" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A24");
                            dispatch(seatChange(700));
                          }}
                        >
                          A24
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A28
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A29" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A29");
                            dispatch(seatChange(0));
                          }}
                        >
                          A29
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A30" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A30");

                            dispatch(seatChange(700));
                          }}
                        >
                          A30
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                        >
                          A34
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A35
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A36" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A36");
                            dispatch(seatChange(700));
                          }}
                        >
                          A36
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A40
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A41" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A41");
                            dispatch(seatChange(700));
                          }}
                        >
                          A41
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A42" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A42");
                            dispatch(seatChange(0));
                          }}
                        >
                          A42
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A43
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A47" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A47");
                            dispatch(seatChange(700));
                          }}
                        >
                          A47
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A48" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A48");
                            dispatch(seatChange(0));
                          }}
                        >
                          A48
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A49
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A53" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A53");
                            dispatch(seatChange(700));
                          }}
                        >
                          A53
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A54" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A54");
                            dispatch(seatChange(0));
                          }}
                        >
                          A54
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A55
                        </Seat>
                      </Grid>

                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A56" ? "#FFA500" : "#6CB4EE",
                          }}
                          onClick={() => {
                            setSelectedSeat("A56");
                            dispatch(seatChange(700));
                          }}
                        >
                          A56
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background:
                              selectedSeat === "A57" ? "#FFA500" : "#32cd32",
                          }}
                          onClick={() => {
                            setSelectedSeat("A57");

                            dispatch(seatChange(0));
                          }}
                        >
                          A57
                        </Seat>
                      </Grid>
                      <Grid item xs={4}>
                        <Seat
                          style={{
                            background: "#cbcbcb",
                          }}
                          disabled
                        >
                          A58
                        </Seat>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Wrapper>
        </Grid>
      </Grid>
      <ButtonFooter />
    </React.Fragment>
  );
}
