import { createSlice } from "@reduxjs/toolkit";

const alertInitialState = {
  state: false,
  type: "success",
  message: "",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState: alertInitialState,
  reducers: {
    alert: (_, { payload }) => ({
      state: true,
      type: payload.type,
      message: payload.message,
    }),

    closeAlert: (state) => ({
      ...state,
      state: false,
    }),
  },
});

export const { alert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
