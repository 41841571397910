import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { setDLData, setAadharData } from "../../redux/slices/documentSlice";
import UserDetailsLicense from "./adhar components/UserDetailsLicense";
import UserDetailsAdhar from "../documents components/adhar components/UserDetailsAadhar";

export default function DetailsPage() {
  const dispatch = useDispatch();
  const { state } = useLocation();

  console.log(state, "sakhkj");

  useEffect(() => {
    if (state.userData.userData) {
      if (state.userData.identityType === "dl") {
        dispatch(setDLData(state.userData));
      }
      if (state.userData.userData.identityType === "aadhaar") {
        dispatch(setAadharData(state.userData));
      }
    }
  }, [dispatch, state.userData]);

  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            Confirm Details
          </Typography>
        </Grid>
      </Grid>

      {state.userData.identityType === "aadhaar" && (
        <UserDetailsAdhar userData={state} />
      )}

      {state.userData.identityType === "dl" && (
        <UserDetailsLicense userData={state} />
      )}
    </React.Fragment>
  );
}
