import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import { Alert, Snackbar, Slide } from "@mui/material";

import "react-perfect-scrollbar/dist/css/styles.css";
import { createTheme } from "@mui/material/styles";
import typography from "../src/theme/typography";
import breakpoints from "../src/theme/breakpoints";
import components from "../src/theme/components";

//import createTheme from "./theme";

import Router from "./router/Router";

import { closeAlert } from "./redux/slices/alertSlice";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alertDetails);

  const theme = createTheme({
    spacing: 4,
    breakpoints: breakpoints,
    // @ts-ignore
    components: components,
    typography: typography,
  });

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | DolphinChat"
        defaultTitle="DolphinChat Dashboard"
      />

      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <Snackbar
                  open={alert.state}
                  autoHideDuration={4000}
                  onClose={handleAlertClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  style={{ top: "8px", right: "10px" }}
                  TransitionComponent={(props) => (
                    <Slide
                      {...props}
                      in={alert.state}
                      direction="right"
                      unmountOnExit
                    />
                  )}
                >
                  <Alert
                    variant="filled"
                    severity={alert.type}
                    onClose={handleAlertClose}
                    style={{ minWidth: "350px" }}
                  >
                    {alert.message}
                  </Alert>
                </Snackbar>

                <Router />
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  }
}

export default App;
