import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Grid as MUIGrid, Typography } from "@mui/material";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
  padding: 10px;
  height: calc(100vh - 350px);
`;

const Grid = styled(MUIGrid)`
  border-radius: 20px 20px 0 0;
`;

export default function PassengerDetails() {
  const navigate = useNavigate();
  const passengerDetails = useSelector((state) => state.passengerDetails);

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ height: "80px" }}>
          <Grid
            container
            spacing={2}
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "#fff",
            }}
          >
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#b2b2b2",
                }}
              >
                Flight No
              </Typography>

              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {passengerDetails.flightNo}
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="end">
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#b2b2b2",
                }}
              >
                PNR No
              </Typography>

              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#333",
                }}
              >
                {passengerDetails.pnrNo}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "17px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                PASSENGER DETAILS
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt="15px">
          <div style={{ height: "calc(100vh - 465px)", overflowY: "scroll" }}>
            <Grid container spacing={2} style={{ paddingRight: "4px" }}>
              {passengerDetails.passengerDetails.map((passenger) => (
                <React.Fragment>
                  <Grid item xs>
                    <Typography
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#333",
                      }}
                    >
                      {passenger.name + " " + passenger.last_name}
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#b2b2b2",
                      }}
                    >
                      {passenger.age + " " + passenger.gender}
                    </Typography>
                  </Grid>
                  <Grid item textAlign="end">
                    <div
                      style={{
                        display: "flex",
                        textAlign: "end",
                        justifyContent: "end",
                      }}
                    >
                      <AirlineSeatReclineNormalIcon
                        style={{
                          height: "18px",
                          width: "18px",
                          color: "#b2b2b2",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#b2b2b2",
                        }}
                      >
                        {passenger.seat_no}
                      </Typography>
                    </div>

                    <Typography
                      variant="button"
                      style={{
                        fontSize: "15px",
                        fontWeight: "300",
                        color: "#01579B",
                      }}
                      onClick={() =>
                        navigate(
                          pages.seatingPage.route +
                            "?pnr=" +
                            passengerDetails.pnrNo +
                            "&userName=" +
                            passengerDetails.userName,
                          { state: { details: passenger } }
                        )
                      }
                    >
                      Change Seat
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ borderBottom: "0.5px solid #b2b2b2" }}
                  />
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
