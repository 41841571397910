import React from "react";
import Cookies from "js-cookie";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import woman from "../../assets/woman.png";
import pages from "../../constants/pages";

const useStyle = makeStyles(() => ({
  outerDivStyle: {
    backgroundSize: "cover",
    background: `linear-gradient(rgb(76 210 25 / 22%) 7.29%, rgb(69 155 1 / 24%) 77.08%),url(${woman})`,
    mixBlendMode: "normal",
    height: "100vh",
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

const Wrapper = styled.div``;

export default function SuccessPage() {
  const classes = useStyle();
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("user-id");
    localStorage.removeItem("user-name");
    localStorage.removeItem("registration-status");
    Cookies.remove("image-data");
    navigate(pages.home.route);
  }

  return (
    <div className={classes.outerDivStyle}>
      <Wrapper>
        <CheckCircleOutline style={{ fontSize: "64px", color: "#fff" }} />
        <Typography
          style={{
            fontSize: "22px",
            fontWeight: "500",
            color: "#fff",
          }}
        >
          Success! You have successfully created digiyatra ID.
        </Typography>

        <div>
          <Typography
            style={{
              fontSize: "14px",
              margin: "5px",
              padding: "7px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            size="small"
            color="primary"
            onClick={() => navigate(pages.boardingPassPage.route)}
          >
            Upload Boarding Pass
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              margin: "5px",
              padding: "7px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            color="primary"
            size="small"
            onClick={() => navigate(pages.digiyatraIdPage.route)}
          >
            Register PNR
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: "5px",
              padding: "7px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            color="primary"
            size="small"
            onClick={handleLogout}
          >
            Logout
          </Typography>
        </div>
      </Wrapper>
    </div>
  );
}
