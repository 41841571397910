import React, { useState } from "react";

import Form from "./Form";
import { signInObject } from "./signInObjects";

function SignIn() {
  const [formData, setFormData] = useState(signInObject);

  return <Form formData={formData} setFormData={setFormData} />;
}

export default SignIn;
