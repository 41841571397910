import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import { setDLData, setAadharData } from "../../redux/slices/documentSlice";
import VideoPreview from "./adhar components/Preview";

export default function PreviewVideo() {
  const dispatch = useDispatch();
  const userData = useLocation();

  useEffect(() => {
    if (userData.state.userData) {
      if (userData.state.userData.identityType === "dl") {
        dispatch(setDLData(userData.state.userData));
      }
      if (userData.state.userData.identityType === "aadhaar") {
        dispatch(setAadharData(userData.state.userData));
      }
    }
  }, [dispatch, userData.state.userData]);

  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            Video Preview
          </Typography>
        </Grid>
      </Grid>
      <VideoPreview userData={userData} />
    </React.Fragment>
  );
}
