export default function convertNormalDate(timeStamp) {
  var date = new Date(timeStamp);

  if (!(timeStamp > 0)) {
    return null;
  }

  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];

  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  var time = day + "-" + month + "-" + year;

  return time;
}
