/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
import DigiYatra from "../../layout/DigiYatraLayout";

import PreviewVideo from "../../pages/documents components/PreviewVideo";
import CaptureImage from "../../pages/documents components/CaptureImage";
import Document from "../../pages/documents components/Document";
import DetailsPage from "../../pages/documents components/DetailsPage";
import Home from "../../pages/login biometric components/Home";
import BoardingPass from "../../pages/boarding pass components/BoardingPass";
import DigiYatraID from "../../pages/digiyatra id components/Form";
import UploadedID from "../../pages/documents components/UploadedIDList";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

const digiyatraRoute = {
  //Main Module Routes

  documentRoute: {
    ID: pages.documentPage.ID,
    path: pages.documentPage.route,
    layout: DigiYatra,
    element: Document,
  },
  captureImage: {
    ID: pages.captureImagePage.ID,
    path: pages.captureImagePage.route,
    layout: DigiYatra,
    element: CaptureImage,
  },
  userDetails: {
    ID: pages.userDetailsPage.ID,
    path: pages.userDetailsPage.route,
    layout: DigiYatra,
    element: DetailsPage,
  },
  previewVideo: {
    ID: pages.previewVideoImage.ID,
    path: pages.previewVideoImage.route,
    layout: DigiYatra,
    element: PreviewVideo,
  },
  selectionRoute: {
    ID: pages.selectionPage.ID,
    path: pages.selectionPage.route,
    layout: DigiYatra,
    element: Home,
  },
  boardingRoute: {
    ID: pages.boardingPassPage.ID,
    path: pages.boardingPassPage.route,
    layout: DigiYatra,
    element: BoardingPass,
  },
  digiyatraIDRoute: {
    ID: pages.digiyatraIdPage.ID,
    path: pages.digiyatraIdPage.route,
    layout: DigiYatra,
    element: DigiYatraID,
  },
  uploadedIDRoute: {
    ID: pages.uploadedIdsPage.ID,
    path: pages.uploadedIdsPage.route,
    layout: DigiYatra,
    element: UploadedID,
  },
};

export default digiyatraRoute;
