import { configureStore } from "@reduxjs/toolkit";

import ENV from "../config/config";
import alertReducer from "./slices/alertSlice";
import loaderReducer from "./slices/loaderSlice";
import passengerReducer from "./slices/userSlice";
import identityReducer from "./slices/documentSlice";

export const store = configureStore({
  reducer: {
    loaderDetails: loaderReducer,
    alertDetails: alertReducer,
    passengerDetails: passengerReducer,
    userIdentityDetails: identityReducer,
  },
  devTools: ENV !== "prod",
});
