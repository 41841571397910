import { Paper as MuiPaperWithoutSpacing, Grid } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

const MuiPaper = styled(MuiPaperWithoutSpacing)(spacing);

export default function Paper({
  gridSpacing,
  variant,
  noGrid,
  gridProps,
  ...props
}) {
  return (
    <MuiPaper
      variant={variant}
      elevation={!!variant ? 0 : 1}
      p="15px"
      mb="10px"
      {...props}
    >
      {Boolean(noGrid) ? (
        props.children
      ) : (
        <Grid
          container
          alignItems="flex-start"
          {...gridProps}
          spacing={Boolean(gridSpacing) ? gridSpacing : 4}
        >
          {props.children}
        </Grid>
      )}
    </MuiPaper>
  );
}
