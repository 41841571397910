import React from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";

import { Divider, Grid, Typography } from "@mui/material";

const Preview = styled.img`
  width: 100%;
  height: 100px;
`;

const useStyle = makeStyles(() => ({
  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function PastBoardingPass() {
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Preview src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_IMG_20220610_173007.968.jpg" />
      </Grid>

      <Grid item xs={8}>
        <Typography>Uploaded On 25 Aug 2022</Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </React.Fragment>
  );
}
