import * as Yup from "yup";
export const signInObject = {
  pnr: "",
  userName: "",
};

export const validationSchema = Yup.object().shape({
  pnr: Yup.string().required("PNR is mandatory."),
  userName: Yup.string().required("Email/Last Name is required."),
});
