import {
  startLoader,
  stopLoader,
  apiComplete,
  apiError,
} from "../redux/slices/loaderSlice";

export default function useLoader() {
  return { startLoader, stopLoader, apiComplete, apiError };
}
