import React, { useEffect } from "react";
import queryString from "query-string";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { IoIosArrowDropright } from "react-icons/io";
import { Grid, Typography, Button } from "@mui/material";
import { AirlineSeatReclineNormal } from "@mui/icons-material";

import woman from "../../assets/woman.png";
import { CheckCircleOutline } from "@mui/icons-material";
import { signInMember } from "../../redux/slices/userSlice";

const useStyle = makeStyles(() => ({
  outerDivStyle: {
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    background: `linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%),url(${woman})`,
    mixBlendMode: "normal",
    height: "100vh",
  },
  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

const Wrapper = styled.div`
  background: #fff;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
  height: calc(100vh - 162px);
`;

export default function SuccessPage() {
  const classes = useStyle();
  const dispatch = useDispatch();

  const pnr = queryString.parse(window.location.search).pnr;
  const userName = queryString.parse(window.location.search).userName;

  useEffect(() => {
    dispatch(signInMember({ pnr: pnr, userName: userName }));
  }, [dispatch, userName, pnr]);

  const passengerDetails = useSelector((state) => state.passengerDetails);

  return (
    <div className={classes.outerDivStyle}>
      <div
        style={{
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          paddingTop: "40px",
          height: "150px",
        }}
      >
        <CheckCircleOutline style={{ fontSize: "64px", color: "#fff" }} />
        <Typography
          style={{
            fontSize: "22px",
            fontWeight: "500",
            color: "#fff",
          }}
        >
          Paid Successfully!
        </Typography>
      </div>
      <Wrapper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                height: "100px",
                background: "#F8F8F8",
                borderRadius: "20px 20px 0 0",
                padding: "0 10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#b2b2b2",
                    }}
                  >
                    Flight No
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#333",
                    }}
                  >
                    {passengerDetails.flightNo}
                  </Typography>
                </Grid>

                <Grid item xs={6} textAlign="end">
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#b2b2b2",
                    }}
                  >
                    PNR No
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#333",
                    }}
                  >
                    {passengerDetails.pnrNo}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#b2b2b2",
                    }}
                  >
                    Transaction ID
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#333",
                    }}
                  >
                    {passengerDetails.transactionID}
                  </Typography>
                </Grid>

                <Grid item xs={6} textAlign="end">
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#b2b2b2",
                    }}
                  >
                    Total Passengers
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#333",
                    }}
                  >
                    {
                      passengerDetails.passengerDetails?.passengerDetails
                        ?.length
                    }
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "#333",
                  }}
                >
                  PASSENGER DETAILS
                </Typography>
              </Grid>
              <Grid item xs={12} mt="15px">
                <div
                  style={{
                    height: "calc(100vh - 530px)",
                    overflowY: "scroll",
                  }}
                >
                  <Grid container spacing={2} style={{ paddingRight: "4px" }}>
                    {passengerDetails.passengerDetails.map((passenger) => (
                      <React.Fragment>
                        <Grid item xs>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            {passenger.name + " " + passenger.last_name}
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#b2b2b2",
                            }}
                          >
                            {passenger.age + " " + passenger.gender}
                          </Typography>
                        </Grid>
                        <Grid item textAlign="end">
                          <div
                            style={{
                              display: "flex",
                              textAlign: "end",
                              justifyContent: "end",
                            }}
                          >
                            <AirlineSeatReclineNormal
                              style={{
                                height: "18px",
                                width: "18px",
                                color: "#b2b2b2",
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#b2b2b2",
                              }}
                            >
                              {passenger.seat_no}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{ borderBottom: "0.5px solid #b2b2b2" }}
                        />
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} textAlign="end">
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#b2b2b2",
                  }}
                >
                  Total Fare Paid
                </Typography>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "#333",
                  }}
                >
                  {passengerDetails.price}
                </Typography>
              </Grid>

              <Grid item xs={12} mt="10px">
                <Button
                  className={classes.buttonStyle}
                  endIcon={<IoIosArrowDropright />}
                  style={{
                    color: "#fff",
                    padding: "5px 35px",
                    fontSize: "16px",
                  }}
                  fullWidth
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
}
