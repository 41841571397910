import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { Grid, Typography, Button } from "@mui/material";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import woman from "../../assets/woman.png";
import TextField from "../../components/TextField";

const useStyle = makeStyles(() => ({
  div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  welcomeTypography: {
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "38px",
    color: "#FFFFFF",
  },

  welcomeDiv: {
    textAlign: "center",
    alignSelf: "center",
  },

  outerDivStyle: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundImage: `url(${woman})`,
    mixBlendMode: "normal",
  },

  innerDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background:
      "linear-gradient(rgb(25 118 210 / 45%) 7.29%, rgba(1, 87, 155, 0) 77.08%)",
  },

  buttonStyle: {
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
  },
}));

export default function SignInBiometric() {
  const axios = useAxios();
  const classes = useStyle();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");

  // function handleLogin() {
  //   function validateEmail(email) {
  //     return String(email)
  //       .toLowerCase()
  //       .match(
  //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //       );
  //   }
  //   var data = {};
  //   const email = validateEmail(username);
  //   if (email) {
  //     data.email = username;
  //     data.password = password;
  //   } else {
  //     data.mobile_no = username;
  //     data.password = password;
  //   }
  //   axios({
  //     url: "/log-in",
  //     method: "POST",
  //     data: data,
  //   }).then((response) => {
  //     if (response.status) {
  //       localStorage.setItem("user-id", response.data.member_id);
  //       localStorage.setItem("user-name", response.data.username);
  //       localStorage.setItem(
  //         "registration-status",
  //         response.data.is_registered
  //       );
  //       navigate(pages.selectionPage.route);
  //     }
  //   });
  // }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handleUserName(e) {
    setUserName(e.target.value);
  }

  function handleLogin() {
    localStorage.setItem("phone-id", username);
    navigate(pages.home.route);
  }

  return (
    <div className={classes.outerDivStyle}>
      <div className={classes.innerDivStyle}>
        <Grid container spacing={4} p="20px">
          <Grid item xs={12} className={classes.welcomeDiv}>
            <Typography variant="h3" className={classes.welcomeTypography}>
              WELCOME USER
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextField
              fullWidth
              type="text"
              label="Mobile Number"
              name="username"
              value={username}
              onChange={handleUserName}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <TextField
              fullWidth
              type="password"
              label="Password"
              name="password"
              value={password}
              onChange={handlePassword}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              style={{ color: "#fff", padding: "10px", fontSize: "18px" }}
              className={classes.buttonStyle}
              fullWidth
              onClick={handleLogin}
              endIcon={<IoIosArrowDropright />}
            >
              Login with password
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
